.header__top {
  background-color: #fff;
  height: 72px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;

  .header__top-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 72px;

    .header__top-left {
      flex-grow: 0;
      align-items: center;
      display: flex;
    }

    .header__top-right {
      display: flex;
      flex-shrink: 0;
      align-items: center;
    }

    .header__nav {
      .separator {
        display: inline-block;
        width: 1px;
        height: 20px;
        margin: 0 20px;
        background: rgba(0, 0, 0, 0.12);
        border: 0;
      }
    }

  }

}

.header__nav {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  .header__nav-list {
    flex-direction: row;
    display: flex;
    flex-grow: 1;
    list-style: none;
    padding-left: 0;
    position: relative;
    align-items: center;

    .header__nav-item {
      display: inline-block;
      flex-grow: 1;
      list-style: none;
      position: relative;

      &.active {
        .header__nav-item-link {
          color: $primary-color;
        }
      }

      .header__nav-item-link {
        display: block;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.87);
        text-decoration: none;
       // padding: 0.5rem 1rem;
      }

      .header__nav-item-link:hover  {
        color: $primary-color;
        text-decoration: none;
      }

      .header__nav-item-label {
        margin-right: 12px;
      }

    }
  }

}

.header__logo {
  display: block;
  flex-shrink: 0;
  margin-right: 48px;
  position: relative;
  width: 116px;
  z-index: 1;

  .logo__img {
    background: url(../img/city-portal-logo-nav.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 100%;
    height: 20px;
  }

  .logo__text {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    color: #003bff;
  }
}

.header__info-icon {
  display: block;
  width:  22px;
  height: 22px;
  background: url(../img/icons/icon-info.svg) no-repeat;
  background-size: contain;
}

.header__feedback-icon {
  display: block;
  width:  22px;
  height: 22px;
  background: url(../img/icons/icon-feedback.svg) no-repeat;
  background-size: contain;
}

.header__action-button {
  display: flex;
  align-items: center;
  max-width: 100%;
  color: #000;
  justify-content: center;
  line-height: 1;
  background: transparent;
  border: none;

  &:hover {
    color: $primary-color;
  }

  .header__button-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 24px;
    //padding: 8px 24px;
    max-width: 100%;
  }

  &.header__action-button-logout  {
    .header__button-inner {
      font-size: 20px;
    }
  }
}

.header__bottom {
  background-color: #F4F4F9;
  position: relative;

  .header__bottom-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 98px;

    .header__bottom-left {
      flex-grow: 0;
      align-items: center;
      display: flex;
    }

    .header__bottom-right {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    .header__nav {
      font-size: 22px;
      font-weight: 400;
      font-style: normal;

      .header__nav-item {
        margin-right: 24px;
      }
    }

  }
}

.header__nav {
  ol, ul {
    margin: 0;
    padding: 0;
  }
}

.header__nav-title {
  display: block;
  flex-shrink: 0;
  position: relative;
  font-family: "Telia Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-right: 48px;
}

.header__languages {
  display: block;
}

.header__actions {
  display: flex;
  align-items: center;

  .header__nav {
    &.header__actions-nav {
      .separator {
        margin: 0 16px;
      }
    }
  }

}

.header__languages-list {
  font-size: 12px;
  margin-right: 14.25px;
  display: flex;
  line-height: 40px;
  font-weight: 400;

  .header__languages-link {
    color: rgba(0, 0, 0, 0.87);
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    outline-offset: -3px;
    padding: 16px  0 0;
  }

  .header__languages-item {
    .header__languages-link {
      text-decoration: none;
    }

    .header__languages-link:hover {
      color: $primary-color;
    }

    &.active {
      .header__languages-link {
        color: $primary-color;
      }

      .header__languages-link::after {
        border-bottom: 3px solid $primary-color;
        content: "";
        display: block;
        padding-top: 13px;
      }

    }
  }

}

.list__inner {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;

  .list__item {
    margin: 0;
    padding: 0 12px;
  }
}
