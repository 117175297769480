 // Icons
.icon {
  display: inline-block;
  width: 60px;
  height: 50px;
  background-size: contain !important;

  &.icon-building {
    background: url(../img/icons/menu-item__house.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__house_a.svg) no-repeat center;
    }
  }

  &.icon-mobility {
    background: url(../img/icons/menu-item__transport.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__transport_a.svg) no-repeat center;
    }
  }

  &.icon-lights {
    background: url(../img/icons/menu-item__lights.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__lights_a.svg) no-repeat center;
    }
  }

  &.icon-weather {
    background: url(../img/icons/menu-item__weather.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__weather_a.svg) no-repeat center;
    }
  }

  &.icon-people {
    background: url(../img/icons/menu-item__people.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__people_a.svg) no-repeat center;
    }
  }

  &.icon-data {
    background: url(../img/icons/menu-item__login.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__login_a.svg) no-repeat center;
    }
  }

  &.icon-other {
    &.colorful {
      background: url(../img/icons/datapoints.svg) no-repeat center;
    }
  }

  &.icon-electric-meter {
    &.colorful {
      width: 105px;
      height: 135px;
      background: url(../img/icons/icon-electric-meter.svg) no-repeat center;
    }
  }

  &.icon-electric-vehicle-charger {
    &.colorful {
      width: 105px;
      height: 135px;
      top: 0 !important;
      background: url(../img/icons/icon-electric-vehicle-charger.svg) no-repeat center;
    }
  }

  &.icon-city-items {
    background: url(../img/icons/menu-icon-city-items.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-icon-city-items_a.svg) no-repeat center;
    }
  }

  &.icon-electric-bicycle-station {
    background: url(../img/icons/menu-item__bicycle-station.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__bicycle-station.svg) no-repeat center;
    }
  }

  &.icon-statistics {
    background: url(../img/icons/menu-item__statistics.svg) no-repeat center;

    &.colorful {
      background: url(../img/icons/menu-item__statistics.svg) no-repeat center;
    }
  }

  &.icon-video {
    background: url(../img/icons/menu-item__icon-video.svg) no-repeat center;
  }

  &.icon-house {
    background: url(../img/icons/nav-icon-house.svg) no-repeat center;
  }

  &.icon-dashboard {
    background: url(../img/icons/nav-icon-dashboard.svg) no-repeat center;
  }

  &.icon-formula {
    background: url(../img/icons/nav-icon-formula.svg) no-repeat center;
  }

  &.icon-datapoint {
    background: url(../img/icons/nav-icon-datapoint.svg) no-repeat center;
  }

  &.icon-mandate {
    background: url(../img/icons/icon-delegation.svg) no-repeat center;
  }

  &.icon-authorization {
    background: url(../img/icons/icon-lock.svg) no-repeat center;
  }

  &.icon-apartment {
    background: url(../img/icons/icon-apartment.svg) no-repeat center;
  }
}

.icon-s {
  display: inline-flex;
  width: 26px;
  height: 26px;
  mask-size: contain !important;

  &.icon-heat {
    width: 20px;
    mask: url(../img/icons/icon-heat.svg) no-repeat center;
    background: $red;
  }

  &.icon-electricity {
    width: 20px;
    mask: url(../img/icons/icon-elect.svg) no-repeat center;
    background: $blue-bright;
  }

  &.icon-water {
    mask: url(../img/icons/icon-water.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-solar {
    mask: url(../img/icons/icon-solar.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-light {
    mask: url(../img/icons/icon-light.svg) no-repeat center;
    background: $yellow;
  }

  &.icon-humidity {
    mask: url(../img/icons/icon-humidity.svg) no-repeat center;
    background: $blue-bright;
  }

  &.icon-gas {
    mask: url(../img/icons/icon-gas.svg) no-repeat center;
    background: $orange;
  }

  &.icon-other {
    mask: url(../img/icons/icon-other.svg) no-repeat center;
    background: $gray-dark;
    mask-size: auto !important;
  }

  &.icon-graph {
    mask: url(../img/icons/menu-item__statistics.svg) no-repeat center;
    background: $gray-dark;
    width: 25px;
  }

  &.icon-bus {
    height: 26px;
    mask: url(../img/icons/bus-icon-primary.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-bustop {
    mask: url(../img/icons/bus-stop.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-route {
    mask: url(../img/icons/route-icon.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-traffic {
    mask: url(../img/icons/stacked-travelling-cars-frontal-view.svg) no-repeat center;
    background: $blue-base;
  }

  &.icon-traffic-sensor {
    background: url(../img/icons/traffic-sensor.svg) no-repeat center !important;
    background-size: contain !important;
    width: 35px;
    height: 26px;
  }

  &.icon-charger-station {
    background: url(../img/icons/charger-station.svg) no-repeat center !important;
    background-size: contain !important;
    width: 27px;
    height: 24px;
  }

  &.icon-bicycle-station {
    mask: url(../img/icons/bicycle-station.svg) no-repeat center !important;
    mask-size: contain !important;
    background: $blue-base;
    width: 57px;
  }

  &.icon-toilets {
    top: 1px;
    position: relative;
    mask: url(../img/icons/toilet.svg) no-repeat center !important;
    mask-size: contain !important;
    color: $blue-base;
    width: 26px;
  }

  &.icon-parchel-terminal {
    top: 1px;
    position: relative;
    mask: url(../img/icons/parchel_terminal.svg) no-repeat center !important;
    mask-size: contain !important;
    color: $blue-base;
    width: 30px;
  }

  &.icon-wind-turbine {
    mask: url(../img/icons/wind_turbine.svg) no-repeat center !important;
    mask-size: contain !important;
    color: $blue-base;
    width: 14px;
  }

  &.icon-datapoint {
    background: url(../img/icons/datapoint_icon.svg) no-repeat center !important;
    background-size: contain !important;
    width: 20px;
  }

  &.icon-datapoint-group {
    background: url(../img/icons/datapoint_group_icon.svg) no-repeat center !important;
    background-size: contain !important;
    width: 20px;
  }

  &.icon-sources {
    mask: url(../img/icons/cloud-solid.svg) no-repeat center !important;
    mask-size: contain !important;
    height: 18px;
    background: #fff;
  }
}

.icon-watermark {
  width: 120px;
  height: 95px;
  display: inline-flex;
  background-size: contain !important;

  &.icon-house {
    background: url(../img/icons/menu-item__house_water.svg) no-repeat center;
  }
}

// Alternative fa icons

.icon-sm {
  display: inline-block;
   mask-size: contain;
   height: 18px;
   width: 18px;
  &.icon-help {
    mask: url(../img/icons/datapoint_group_icon.svg) no-repeat center !important;
  }

   &.icon-pending {
     mask: url(../img/icons/icon-status-pending.svg) no-repeat center !important;
   }

   &.icon-success {
    mask: url(../img/icons/icon-status-success.svg) no-repeat center !important;
   }

   &.icon-in-progress {
    mask: url(../img/icons/icon-status-in-progress.svg) no-repeat center !important;
   }
  &.icon-cancel {
     mask: url(../img/icons/icon-status-cancel.svg) no-repeat center !important;
  }
  &.icon-info-outline-primary {
    vertical-align: middle;
    background: url(../img/icons/icon-info-outline-primary.svg) no-repeat center;
  }
}

.icon-xs {
  display: inline-block;
  mask-size: contain;
  height: 16px;
  width: 16px;
  &.icon-edit {
    background: url(../img/icons/icon-edit.svg) no-repeat center;
  }
  &.icon-edit-selected {
    background: url(../img/icons/icon-edit-selected.svg) no-repeat center;
  }
}

.icon-status {
   display: inline-block;
   height: 16px;
   width: 16px;
   margin-bottom: 0.2rem;

   &.status-pending {
     background: url(../img/icons/icon-status-pending.svg) no-repeat center !important;
   }

   &.status-success {
    background: url(../img/icons/icon-status-success.svg) no-repeat center !important;
   }

   &.status-in-progress {
    background: url(../img/icons/icon-status-in-progress.svg) no-repeat center !important;
   }
  &.status-cancel {
     background: url(../img/icons/icon-status-cancel.svg) no-repeat center !important;
  }

}

i[class*="fal"] {
   &.icon-md {
       font-size: $font-size-base * 1.4;
     }

     &.icon-lg {
       font-size: $font-size-base * 1.6;
     }
}

.icon-dashboard {
  display: inline-block;
  height: 91px;
  width: 61px;

  &.advices {
     background: url(../img/icons/icon-energy-advice.svg) no-repeat center !important;
   }

   &.questionnaires {
     height: 66px;
     width: 61px;
     background: url(../img/icons/icon-questionnaires.svg) no-repeat center !important;
  }
}

.icon-bullet {
  display: inline-block;
  height: 33px;
  width: 32px;
  background-size: contain;
  background: url(../img/icons/icon-bulletpoint.svg) no-repeat center !important;
}

.icon-checked {
  display: inline-block;
  height: 20px;
  width: 24px;
  mask-size: contain;
  mask: url(../img/icons/icon-checked.svg) no-repeat center !important;
  background: $gray-300;
}
