// Dropdowns
.dropdown-menu-right {
  @include media-breakpoint-down(md) {
    position: absolute !important;
    left: 0 !important;
    right: auto !important;
  }
}

.dropdown-menu-floating-btn {
  @include media-breakpoint-down(sm) {
    position: absolute !important;
    transform: translate(-100%, -100%) !important;
    left: -10px !important;
  }
}

.bill-count {
  display: flex;
  position: absolute;
  top: 15px;
  right: 4px;
  height: 18px;
  padding: 0;
  min-width: 18px;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary-color;
  background: #ffffff;
  color: $primary-color;
  border-radius: 50%;
}

// Sidebar
.side-navbar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: $menu-color;
  width: $sidebar-width;
  height: 100vh;
  overflow: hidden;
  z-index: 5;

  .logo-wrapper {
    display: flex;
    width: auto;
    height: 100px;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
  }

  .language-select {
    text-align: center;

    a {
      display: inline-block;
      height: auto;
      line-height: normal;
      margin: 0 5px;
      padding: 0;
      font-size: $font-size-base;
      color: #9c77d5;

      &:hover {
        color: #9c77d5;
      }

      &.active {
        color: #fff;
      }
    }
  }

  .nav {
    .nav-item {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      outline: none;

      .nav-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0.7rem 0 !important;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-weight: 400;
        font-size: $font-size-base;
        cursor: pointer;
        &:hover {
          cursor: pointer;
        }

        .icon {
          display: block;
          margin: auto;
          margin-bottom: 0.5rem;
          background-position: bottom;
          @include media-breakpoint-down(sm) {
            width: 50px;
            height: 40px;
          }
        }
      }

      &:hover {
        transition: all 0.3s ease-in-out 0s;
        background-color: darken($menu-color, 5%);

        .icon-building {
          background: url(../img/icons/menu-item__house_a.svg) no-repeat bottom;
        }

        .icon-mobility {
          background: url(../img/icons/menu-item__transport_a.svg) no-repeat bottom;
        }

        .icon-lights {
          background: url(../img/icons/menu-item__lights_a.svg) no-repeat bottom;
        }

        .icon-weather {
          background: url(../img/icons/menu-item__weather_a.svg) no-repeat bottom;
        }

        .icon-people {
          background: url(../img/icons/menu-item__people_a.svg) no-repeat bottom;
        }

        .icon-data {
          background: url(../img/icons/menu-item__login_a.svg) no-repeat bottom;
        }

        .icon-city-items {
          background: url(../img/icons/menu-icon-city-items_a.svg) no-repeat bottom;
        }

        :after {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          content: "";
          border-right: 3px solid #FF3264;
        }
      }
    }

    .active {
      .nav-link {
        background-color: darken($menu-color, 5%);

        .icon-building {
          background: url(../img/icons/menu-item__house_a.svg) no-repeat bottom;
        }

        .icon-mobility {
          background: url(../img/icons/menu-item__transport_a.svg) no-repeat bottom;
        }

        .icon-lights {
          background: url(../img/icons/menu-item__lights_a.svg) no-repeat bottom;
        }

        .icon-weather {
          background: url(../img/icons/menu-item__weather_a.svg) no-repeat bottom;
        }

        .icon-people {
          background: url(../img/icons/menu-item__people_a.svg) no-repeat bottom;
        }

        .icon-data {
          background: url(../img/icons/menu-item__login_a.svg) no-repeat bottom;
        }

        .icon-city-items {
          background: url(../img/icons/menu-icon-city-items_a.svg) no-repeat bottom;
        }

        :after {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          content: "";
          border-right: 3px solid #FF3264;
        }
      }

      &.green-item {
        .nav-link {
          background-color: $green-base;

        }
      }
    }
  }
  .modal-header {
    display: none;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    .modal-header {
      background: none;
      display: block;
      .close {
        color: white;
        opacity: 1;
        left: 8px;
        top: 8px;
      }
    }
  }
}

.top-navbar {
  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.09);

  .navbar-light {
    padding: 0;
    background: #fff;

    .nav-item {
      border-left: 1px solid $border-color;
      &:first-child {
        border-left: none;
      }

      .nav-link {
        display: flex;
      }
    }

    .nav-icon {
      height: 60px;
      .navbar-nav {
        height: 100%;

        .nav-item {
          border: none;
        }
      }

      .nav-link {
        flex-direction: row;
        height: 100%;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        i {
          font-size: 1.6rem;
        }
      }
    }
  }

  .navbar-mydata {
    .nav-link {
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .icon {
        width: 28px;
        height: 30px;
      }

      span {
        font-size: $font-size-sm;
        text-transform: uppercase;
      }
    }
  }

  .sub-bar {
    display: flex;
    padding: 0 1rem;
    min-height: 60px;
    align-items: center;
    background: $bg-gray;
    border-top: 1px solid $border-color;

    .title {
      font-family: "Pebble", Arial, Helvetica, sans-serif;
      font-size: 1.4rem;
      text-transform: uppercase;
    }

    .navbar-nav {
      flex-direction: row;
      .nav-item {
        padding: 0 1.5rem;
        .nav-link {
          flex-direction: row;
          height: 100%;
          align-items: center;
          color: $gray-dark;
          &:hover {
            color: $dropdown-link-hover-color;
          }
          &.active {
            color: $primary-color;
          }
          @include media-breakpoint-down(md) {
            width: max-content;
          }
        }
      }
      @include media-breakpoint-down(md) {
        overflow: scroll;
        padding-top: 10px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding: 1rem;
      width: 100%;
      display: block;

      .action-buttons {
        justify-content: center;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .btn {
          margin: 0.5rem 0;
        }
      }
    }
  }

  .bill-count {
    display: flex;
    position: absolute;
    top: 15px;
    right: 4px;
    height: 18px;
    padding: 0;
    min-width: 18px;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary-color;
    background: #ffffff;
    color: $primary-color;
    border-radius: 50%;
  }

  .language-select {
    .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;

      &:hover {
        color: #9c77d5;
      }

      &.active {
        color: #fff;
      }
    }
  }
}


// Nav links

.nav-links {
  .nav-item {
    .nav-link {
      font-size: $font-size-base;
      color: $gray-dark;
      text-transform: uppercase;

      &:hover {
        color: $primary-color;
      }

      &.active {
        color: $primary-color;
      }

      &.disabled {
        color: #cdcdcd;
      }
    }

    &.separator {
      display: inherit;
      margin: 0;
      border-bottom: inherit;
      span {
        position: relative;
        margin: 0 .5rem;
        border-left: 1px solid #E4E4E4;
      }
    }
  }

  &.nav-links-small {
    .nav-item {

      .nav-link {
        font-size: $small-font-size;
        padding: 0.3rem 0.5rem;
      }

      &.separator {
        span {
          top: 0;
          font-size: $small-font-size;
        }
      }
    }
  }
}

.language-select {
  margin-right: 1rem;
}

.navbar-wizard {
  flex-direction: row;
  margin-bottom: -1.5rem;
  align-items: center;
  justify-content: left;
  margin-top: 1rem;

  .nav-item {
    position: relative;
    padding: 0 1.5rem;

    &:before {
      position: absolute;
      right: 0;
      top: 5px;
      font-family: "Font Awesome 6 Pro";
      content: "\f105";
      border: none;
      font-size: 1.2rem;
      font-weight: 400;
      color: $border-color;
    }

    &:last-of-type:before {
      display: none;
    }

    .nav-link {
      position: relative;
      padding: 0.5rem 1.5rem;
      cursor: pointer;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }
      &.active {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          content: "";
          border-bottom: 2px solid $primary-color;
        }
      }

      &.disabled {
        color: #d1d3d6;
      }
    }
  }
}

.navbar-tabs {
  flex-direction: row;
  margin-bottom: -1rem;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;

  .nav-item {
    position: relative;
    padding: 0 1rem;

    .nav-link {
      position: relative;
      padding: 0.5rem 1rem;
      cursor: pointer;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }
      &.active {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          content: "";
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
}

// navbar styles for mobile

.top-navbar-mobile {
  visibility: hidden;
  position: relative;
  top: 0;
  z-index: 4;
  width: 100%;
  height: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  @include media-breakpoint-down(md) {
    visibility: visible;
    height: inherit;
  }
  .navbar-purple {
    flex-wrap: nowrap !important;
    align-items: stretch !important;
    padding: 0;
    background: $menu-color;
    width: 100%;
    .navbar-toggler {
      background: $dark-purple;
      color: white !important;
      outline: none !important;
      border: none !important;
      border-radius: 0 !important;
    }
  }

  .navbar-light {
    flex-wrap: nowrap;
    padding: 0;
    background: #fff;
    border-bottom: 1px solid $border-color;
    justify-content: inherit;
    .nav-item {
      .nav-link {
        display: flex;
      }

    }
    .nav-icon {
      height: 60px;
      flex-direction: row;

      .navbar-nav {
        flex-direction: row;
        height: 100%;

        .nav-item {
          border: none;
        }
        &.language-select {
          margin-left: 1rem;
          .nav-item {
            .nav-link {
              padding: 0 .8rem;
            }
          }
        }
      }
      .nav-link {
        flex-direction: row;
        height: 100%;
        padding: 0 1rem;
        align-items: center;

        i {
          font-size: 1.6rem;
        }
      }

      &.nav-icon-border {
        .nav-link {
          border-left: 1px solid $border-color;
        }
      }
    }
  }
}

.bottom-navbar-mobile {
  visibility: hidden;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0;
  z-index: 4;
  background: $menu-color;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  @include media-breakpoint-down(md) {
    visibility: visible;
  }
  .navbar-nav {
    flex-direction: row !important;
    justify-content: space-around !important;
    width: 100%;
    .nav-item {
      width: 100%;
      &.active {
        background-color: $dark-purple;
      }
      .nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        .icon {
          width: 28px;
          height: 30px;
          &.icon-authorization {
            background: url(../img/icons/icon-lock-w.svg) no-repeat center;

          }
          &.icon-mandate {
            background: url(../img/icons/icon-delegation-w.svg) no-repeat center;
          }
          &.icon-datapoint {
            mask: url(../img/icons/nav-icon-datapoint.svg) no-repeat center;
            background: white;
          }
        }
        span {
          font-size: $font-size-sm;
          text-transform: uppercase;
        }
      }
    }
  }
}
