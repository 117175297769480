// Overrides mat-slider

.mat-slider {
  padding: 0 !important;

  &.mat-slider-horizontal {
    height: 40px !important;
    .mat-slider-wrapper {
      height: 2px;
      top: 22px;
      left: -2px;
      right: 8px;
    }
  }

  .mat-slider-track-background {
    background-color: #c2c0c2;
  }

  .mat-slider-track-fill {
    background-color: $primary-color;;
  }
  .mat-slider-thumb, .mat-slider-thumb-label {
    background-color: $primary-color;;
  }
}
