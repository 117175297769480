// Buttons

.btn {
  text-transform: uppercase;
}

button:focus {
  outline: none;
}

// Icon button
.btn-icon {
  color: $btn-color;
  padding: $btn-padding-y;

  &.icon-md {
    font-size: $font-size-base * 1.4;
  }

  &.icon-lg {
    font-size: $font-size-base * 1.6;
  }

  &.active {
    color: $primary-color;
  }
}

//Link button

.btn-link {
  text-transform: uppercase;
  background: none;
  border: none;
}

//Info button

.btn-tooltip {
  color: $primary-color;
  padding-top: 0;
  padding-bottom: 0;
}

.tooltip-icon {
  cursor: pointer;
}

.tooltip-lg {
  font-size: 1.5rem;
}


//More button

.btn-more {
  .dropdown-toggle::after {
    display: none;
  }

  .btn-icon {
    padding: $btn-padding-y $btn-padding-x ;
    font-size: 1.6rem;
    cursor: pointer;

    @include hover() {
      background: darken($light, 5%);
    }
  }
}

// Floating button

.fixed-action-btn {

  .btn-floating {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    min-width: 36px;
    height: 36px;
    margin: 0 1rem;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    transition: .2s ease-in-out;
    border-radius: $border-radius-base;
    padding: 0;
    cursor: pointer;

    &.green {
      background: $secondary-color;
    }

    @include hover() {
      background: darken($secondary-color, 10%);
    }
  }

  .btn-slide {
    display: flex !important;
    align-items: center;
    padding: 0 1rem;
    border-radius: $border-radius-base;
    color: #fff !important;
    transition: width 1s ease-in-out !important;

    span {
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 300;
    }

    i {
      width: auto;
    }
  }
}

.floating-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 74px;
  height: 74px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.14);
  cursor: pointer;

  @include hover () {
    background: darken($light, 0.5%);
  }


  .icon-dashboard {
    width: 40px;
    height: 48px;
    background-size: contain !important;
    &.questionnaires {
      width: 35px;
      height: 48px;
    }
  }
}


// Btn group separate

.btn-separate {

  &.btn-group-sm {
    .btn {
      font-size: .875rem;
      line-height: 1;
      padding: 0.3rem 1rem;
    }
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) {
    margin-left: 1rem;
  }

  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
      border-radius: $border-radius-base;
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    border-radius: $border-radius-base;
  }

  .btn-outline-lighter {
    &.active {
      background: #fff !important;
      border-color: $primary-color !important;
      color: $primary-color !important;
    }
  }
}

.btn-filter {
  border-radius: 0.3rem;
  padding: 0.1rem 0.8rem;
  font-size: 1.4rem;
  border-color: $border-color;

  &.active {
    color: $gray-dark !important;
    background-color: #d2d2d2 !important;
    border-color: #afafaf !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

// Button table

.table .btn {
  white-space: nowrap;
}


.button-link {
  color: $primary-color !important;

  .icon-caret {
    position: relative;
    top: 3px;
  }

  > i {
    margin-right: 5px;
  }
}

.btn-remove {
  font-size: 12px;
  color: #a7a7a7 !important;
  cursor: pointer;

  &:hover {
    color: $primary-color !important;
  }
}

.btn-tooltip {
  cursor: pointer;
}

@include media-breakpoint-up(xs) {
  .fixed-action-btn {
    left: 150px;
  }
}

// Tags

.chip {
  display: inline-block;
  padding: 0.4rem 1rem 0.3rem 1rem;
  margin-right: .5rem;
  margin-bottom: .5rem;
  font-size: $small-font-size;
  font-weight: 500;
  border-radius: 1rem;
  cursor: pointer;
  background: #fff;
  color: $primary-color;

  &.chip-primary, &.active {
    background: $primary-color;
    color: #fff;
  }

  &.chip-remove {
    position: relative;
    padding-right: 2rem;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.6rem;
      font-size: $font-size-base;
      color: #fff;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  &:hover {
    transition: all .3s linear;
  }
}

.save-edit {
  position: absolute;
  right: -16px;
  top: -18px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 0;
  box-shadow: 0 6px 10px 0 #b2b2b2;
  i {
    font-size: 1.4rem;
  }
}

//Floating button in mobile

.btn-floating-mobile-container {
  display: none;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 5;
    bottom: 80px;
    right: 10px;

    >:first-child{
      margin-bottom: 15px;
    }
  }
}

.btn-floating-mobile {
  @include media-breakpoint-down(md) {
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
  }

}

// Scroll to top

.scroll-to-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  opacity: 0;
  transition: all .2s ease-in-out;
  button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 100%;
    i {color: $white}
  }
}
.show-scrollTop {
  opacity: 1;
  transition: all .2s ease-in-out;
}

// Button mapped status

.btn-mapped-status {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0.5rem;
}

.checkbox-wrapper {
  display: inline-block;
  margin: 0.5rem !important;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
}
