// Fonts

@font-face {
  font-family: "Pebble";
  src: local(Pebble), url("//font/pebble-font/Pebble_WLatn_Rg.eot");
  src: url("../font/pebble-font/Pebble_WLatn_Rg.eot?#iefix") format("embedded-opentype"),
  url("../font/pebble-font/Pebble_WLatn_Rg.woff2") format("woff2"),
  url("../font/pebble-font/Pebble_WLatn_Rg.woff") format("woff"),
  url("../font/pebble-font/Pebble_WLatn_Rg.ttf") format("truetype");
}

@font-face {
  font-family: 'Telia Sans';
  src: url('../font/telia-sans/TeliaSans-Regular.eot');
  src: local('../font/telia-sans/Telia Sans Regular'), local('TeliaSans-Regular'),
      url('../font/telia-sans/TeliaSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('../font/telia-sans/TeliaSans-Regular.woff2') format('woff2'),
      url('../font/telia-sans/TeliaSans-Regular.woff') format('woff'),
      url('../font/telia-sans/TeliaSans-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Telia Sans';
  src: url('../font/telia-sans/TeliaSans-Bold.eot');
  src: local('../font/telia-sans/Telia Sans Bold'), local('TeliaSans-Bold'),
  url('../font/telia-sans/TeliaSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../font/telia-sans/TeliaSans-Bold.woff2') format('woff2'),
  url('../font/telia-sans/TeliaSans-Bold.woff') format('woff'),
  url('../font/telia-sans/TeliaSans-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("../font/open-sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: url("../font/roboto/Roboto-Medium.ttf") format("truetype");
}

// Headings

h1 {
  text-transform: uppercase;
  font-size: 2.125rem;
  margin-bottom: 50px;
}

h2 {
  text-transform: uppercase;
  font-size: 1.625rem;
  margin-bottom: 1rem
}

h3 {
  text-transform: uppercase;
  font-size: 1.125rem;
  margin-bottom: 1rem
}


h4 {
  text-transform: uppercase;
  font-size: 1rem;
  margin-bottom: 1rem
}

.section-title {
  color: $light-text;
}

.small-text {
  font-size: $small-font-size;
}



// Helpers

.text-light, .help-text {
  font-size: $small-font-size;
  color: $light-text !important;
}

.w-300p {
  width: 300px;
}

.w-200p {
  width: 200px;
}

.w-250p {
  width: 250px;
}

.w-150p {
  width: 150px;
}

.w100p, .w-100p {
  width: 100px;
}

.w-55p {
  width: 55px;
}

.font-light {
  font-weight: 300;
}
