// Tables

.table thead th {
  border-top: none;
  font-weight: 400;
}

.table-sm {
  thead th, thead td {
    padding: 0.4rem 1.2rem;
    font-size: $small-font-size;
    background-color: inherit;
    color: #fff;
    text-transform: uppercase;
    background: $btn-color;
    border-bottom: $table-border-width solid $table-border-color;;
  }

  tbody td {
    padding: 0.4rem 1.2rem;
  }

  &.btn-table {
    td {
      vertical-align: middle;
    }
  }
}

.table-selectable {
  margin-top: 1rem;
  .form-check-input[type="checkbox"] + label {
    height: 1.2rem;
  }

  .badge {
    padding: 0.5rem 1rem;
  }
}

.table-with-subtable > tbody {
  background: #f5f5f5;

  .subtable {
    display: none;
    background: $white;

    > td {
      padding: 0;
    }

    &.show {
      display: table-row;
    }

    .table {
      background: #fff;
      margin-bottom: 0;

      tbody td:first-child {
        padding-left: 30px;
      }
    }
  }
}

// Ngx-datatable material

.ngx-datatable.material {
  background: inherit;
  box-shadow: none;
  overflow: visible;

  .datatable-header {
    border-bottom: none;

    .datatable-header-cell {
      padding: 0.4rem 1.2rem;
      font-weight: $small-font-size;
      background-color: inherit;
      color: $datatable-header-cell-color;
      text-transform: uppercase;

      .checkbox.position-absolute {
        margin-top: 2px;
      }

      &.sortable {
        color: $primary-color;

      .datatable-header-cell-wrapper:hover {
        color: darken($primary-color, 20%);
       }
      }
    }
  }

  .datatable-body {
    .datatable-body-row {
      margin-bottom: 2px;
      .datatable-body-cell {
        display: flex;
        padding: 0.7rem 1.2rem;
        align-items: center;
        background: #fff;
        vertical-align: middle;
        color: $datatable-body-cell-color !important;
        font-size: $font-size-base !important;
        overflow-x: visible;

        .title {
          display: block;
          color: $primary-color;
          cursor: pointer;
        }

        .help-text {
          color: $light-text;
          font-size: $small-font-size;
        }

        .status {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-size: $small-font-size;
        }
      }

      &.active {
        .datatable-body-cell {
          background: $bg-active;
        }
      }

    }
  }

  .datatable-footer {
    border-top: none;
    font-size: $small-font-size;
    color: $datatable-footer-cell-color;
  }

  .empty-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    background: #fff;
  }
}

//DAL table

// Virtual scrolleri jaoks eraldi stiilid - ei tööta HTML table-ga
.sources-list {
  width: 100%;
  display: block;
  font-size: 0.875rem;
  margin-bottom: 5rem;

  .sources-list-item {
    display: block;
    background: #fff;
    border-top: 1px solid #D5D5D5;

    min-height: 95px;

    &:hover {
      background: $light-gray;
    }
  }

  .checkbox {
    justify-content: center;
    input[type=checkbox] {
      margin: 0;
    }
  }

  .mapped-status .badge {
    margin: auto 1.4rem auto 0;
    padding: 0.5rem 1rem;
  }

}

.table-mapping {
  font-size: 0.875rem;

  .results-title {
    margin: 0 3rem;
    color: $gray-200;
    font-size: $font-size-base;
    font-weight: $font-weight-bolder;
  }
  .badge-warning {
    color: $white;
  }
  thead {
    background-color: $datatable-header-cell-background;
    tr:first-of-type th {
      padding: .4rem 0.4rem;
      color: $gray-700;
      text-transform: uppercase;
      border: none;
      white-space: nowrap;
      @include media-breakpoint-down(md)  {
        min-width: 200px;
      }
    }

    tr:last-of-type th {
      padding: .75rem 0.4rem;
     // background: $gray-800;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }

    .checkbox {
      display: flex;
      position: relative;
      cursor: pointer;

      input[type=checkbox] {
        margin: 0 1rem 0 0;
      }
    }
  }

  tbody {
    .bg-highlight {
      background-color: $bg-highlight;
    }
    tr {
      td {
        vertical-align: middle;
        padding: 0.75rem 0.4rem;

        .checkbox {
          justify-content: center;
           input[type=checkbox] {
            margin: 0;
          }
        }

        .empty {
          color: $blue-base;
          font-style: italic;
        }

        &.editable {
          &:focus {
            outline: 1px solid $blue-base;
          }
        }
      }

      &:hover {
        td {
          background: $light-gray;
        }
      }

      &.table-actions {
        td {
          background: #f3f3f3;
        }

        &:hover td {
          background: inherit;
        }
      }

      &.bulk-edit-active {
        position: relative;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

        .save-edit {
          position: absolute;
          top: -22px;
          right: -22px;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          padding: 0;
          line-height: 46px;

          i {
            font-size: 1.2rem !important;
          }
        }

        td {
          background: #ececec;
          .form-control {
            border: 1px solid #ced4da;
            box-shadow: none;
          }
        }


        .md-form {
          margin: 0;
          .completer-input {
            border: 1px solid #ced4da;
            margin: 0;
            padding: 0.475rem 0.75rem;
            box-sizing: border-box;
          }
        }

        .md-form input[type=text]:focus:not([readonly]) {
          box-shadow: 0 1px 0 0 $light-gray;
          border-bottom: 1px solid $light-gray;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  .mapped-status .badge {
    margin: auto 1.4rem auto 0;
    padding: 0.5rem 1rem;
  }
}

.selected {
  background-color: #e7f8ff !important;
}

.nav-links {
  .nav-link {
    padding: 0.5rem 0.5rem;
  }
}

.ngx-pagination  {
  li {
    a {
      &:hover {
        background: none;
        text-decoration: underline !important;
      }
    }

    &.current  {
      background: $primary-color;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
  }
}
