// Form elements

label {
  margin-bottom: 0.3rem;
  color: $gray-700;
  text-transform: uppercase;
  font-size: $small-font-size;

  &.light {
    color:  lighten( $light-text, 15%);
  }

  &.col-form-label {
    font-size: $small-font-size;
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: left;
    }
  }

  .optional {
    display: block;
    text-transform: none;
    font-style: italic;
    font-weight: 300;
    line-height: 1;
    color: $gray-300;
  }

  &.col-form-label-other {
    padding-top: 0.2rem;
  }
}


.form-check-label {
  color: $body-color;
}

.select-link {
  .form-control {
    margin-top: 5px;
    border: none;
    text-transform: uppercase;
    color: $primary-color;
    font-weight: 400;
    line-height: 1;
    height: auto;

    .placeholder, .value {
      color: $primary-color;
      font-weight: 400;
    }

    .toggle {
      color: $primary-color;
    }
  }
}

// Form  with sticky footer

.form-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 305px);
  @include media-breakpoint-down(sm) {
    //min-height: inherit;
    padding-bottom: 7.5rem !important;
  }
}

.form-footer {
  padding: $modal-header-padding-y $modal-header-padding-x;
  background: $white;
  border: none;
  text-align: right;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  z-index: 2;

  @include media-breakpoint-down(sm) {
    position: fixed;
    margin-bottom: 60px;
    left: 0;
    right: 0;
  }
}

// Search
.form-search {
    background: #fff;
    border-radius: $border-radius-base;
    border: $input-border-width solid $input-border-color;

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &:focus {
      border-color: $input-focus-border-color;
    }

    > .form-control {
      border: none;
      font-size: 1rem;
      border-radius: $form-border-radius;

      &:focus {
        box-shadow: none;
      }
    }

    .clear-search {
      display: inline-flex;
      padding: 0 0.5rem;
      align-items: center;
      color: $light-text;
      cursor: pointer;
      opacity: 0;
      animation-name: fadeOut;
      animation-duration: .5s;

      &:hover {
        color: darken($light-gray, 20%);
      }

      &.active {
        opacity: 1;
        animation-name: fadeIn;
        animation-duration: .5s;
      }
    }

    .input-group-text {
      border: none;
      background: none;
      border-radius: $form-border-radius;
    }

}

.ngx-mat-drp-date-display {
  .suffix-icon {
    display: inline-flex;
    padding: 0;
    margin-left: 10px;
    margin-top: 3px;
    align-items: center;
    color: $light-text;
    cursor: pointer;
    opacity: 0;
    font-size: 1.2rem;
    animation-name: fadeOut;
    animation-duration: .5s;

    &:hover {
      color: darken($light-gray, 20%);
    }

    &.active {
      opacity: 1;
      animation-name: fadeIn;
      animation-duration: .5s;
    }
  }
}

.file-field input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.sub-list {
  margin-left: 2rem;
}

.select {
  .form-control {
    font-size: 0.875rem;
    font-weight: 300;
    height: 2rem;

    .placeholder {
      color: #757575;
    }
  }
}

// The dropdown menu

.custom-dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;

  &.no-caret {
    &::after {
     display: none;
    }
  }
}

.dropdown-menu {
  border: none;
  box-shadow: $dropdown-box-shadow;
  padding: 0;
  .dropdown-item {
    cursor: pointer;

    i[class*="fal"] {
      margin-right: 5px;
    }
  }
}

.custom-dropdown {
  .btn-link {
    &.btn-sm {
      padding: 0.2rem;
    }
  }
}

.filter-dropdown {
  .dropdown-toggle {
    height: 100%;
    padding: 0.37rem 0.37rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: $navbar-light-color;

    @include hover-focus() {
      color: $navbar-light-hover-color;
    }

    &::before {
      position: relative;
      top: 2px;
      margin-right: 6px;
      font-family: "Font Awesome 6 Pro";
      content: "\f107";
      border: none;
      font-size: 1.2rem;
      font-weight: 400;
    }
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    min-width: 200px;

    .accordion-group {
      border-top: 1px solid $border-color;
      &:first-of-type {
        border-top: none;
      }

      &:not(.show) {
        .accordion-content {
          display: none;
          height: 0;
          overflow: hidden;
          animation: slide 1s ease 2.5s forwards;
        }

        .accordion-header {
          i[class*="fal"] {
            transform: rotate(0);
          }
        }
      }

      .accordion-header {
        display: block;
        width: 100%;
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        clear: both;
        font-weight: $font-weight-normal;
        color: $navbar-light-color;
        text-align: inherit;
        white-space: nowrap;
        cursor: pointer;

        @include hover-focus() {
          color: $navbar-light-hover-color;
        }

        i[class*="fal"] {
          position: relative;
          top: 2px;
          font-size: 1.2rem;
          margin-right: 5px;
          font-weight: 400;
          transform: rotate(180deg);
        }
      }

      .accordion-content {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        padding-top: 0;
      }
    }
  }
}

.filter-tag {
  margin: 0 1rem;
  font-size: $small-font-size;
  white-space: nowrap;

  i[class*="fal"] {
    position: relative;
    top: 1px;
    font-size: 1rem;
    margin-left: 5px;
    cursor: pointer;
    padding: 2px 5px;

    &:hover {
      background: $gray-400;
    }
  }
}

// Checkbox

.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;

  input[type=checkbox] {
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    outline: none;
   // height: fit-content;
    height: 14px;

    &:before {
      transition: all .3s ease-in-out;
      content: "";
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border: 2px solid $border-color;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      background: #fff;
      cursor: pointer;
    }

    &:checked:before {
      transform: rotate(-45deg);
      height: .5rem;
      border-color: $primary-color;
      border-top-style: none;
      border-right-style: none;
    }
  }
}


//Switch

.custom-switch {
  .custom-control-label {
    text-transform: inherit;
    font-size: inherit;
    color: inherit;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    &::before {
     border-color:  lighten($border-color, 25%);
    }
  }

  .custom-control-input:checked:disabled ~ .custom-control-label {
    &::before {
      background-color: $custom-control-indicator-active-disabled-bg;
    }
  }
}

.modal-header {
  .custom-switch {
    font-size: $small-font-size;
    .custom-control-label {
      line-height: 1.8;
    }
  }
}

mat-tree-node {
  .mat-checkbox-layout {
    white-space: normal;
    word-break: break-all;
    text-align: left;

    .mat-checkbox-inner-container {
      word-break: break-word;
    }
  }
}
