// Main Components

html {
  height: 100%;
  font-size: $root-font-size-base;
  margin: 0;
}

body {
  height: 100%;
  background: $white;
}

main {
 @include media-breakpoint-down(md) {
   padding-bottom: 60px;
 }
}

:focus {
  outline: none;
}


section {
  margin-top: 2rem;
  &:first-of-type {
    margin-top: 0;
  }
}

// Landing page

.landing-page {
  .logo-wrapper {
    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
    }
  }
}


// Content

.map-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.tooltip-bottom {
  .arrow::before {
    border-width: 0 .4rem .4rem;
    border-bottom-color: #666;
  }
}

.tooltip-top {
  .arrow::before {
    border-width: .4rem .4rem 0;
    border-top-color: #666;
  }
}

.title-bar {
  align-items: center;

  h3 {
    margin: 0;
  }
}

// Map pointers

.pointer {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: -4px;
    left: -4px;
    display: inline-block;
    content: "";
    width: 40px;
    height: 40px;
    border: 4px solid;
    border-radius: 50%;
  }

  &.blue {
    background: $blue-bright !important;

    &:before {
      border-color: rgba(0, 153, 255, .2);
    }

  }

  &.green {
    background: $green-base;

    &:before {
      border-color: rgba(0, 204, 120, .2);
    }
  }

  &.red {
    background: $red;

    &:before {
      border-color: rgba(255, 50, 100, .2);
    }
  }
}

.pointer-bus-stop {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 25px;
  height: 39px;
  background: url("../img/icons/pointer-bus-stop.png") no-repeat;
}

.pointer-bus-stop-active {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-bus-stop-active.png") no-repeat;
}

.pointer-bus-stop-larger {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-bus-stop-larger.png") no-repeat;
}

.pointer-bus-icon {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 34px;
  height: 14px;
  background: url("../img/icons/pointer-bus-icon.png") no-repeat;
}

.pointer-bus-icon-on {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 34px;
  height: 14px;
  background: url("../img/icons/pointer-bus-icon-green.png") no-repeat;
}

.pointer-bus-icon-stop {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 34px;
  height: 14px;
  background: url("../img/icons/pointer-bus-icon-stop.png") no-repeat;
}

.pointer-bus-icon-active {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 63px;
  height: 23px;
  background: url("../img/icons/pointer-bus-icon-active.png") no-repeat;
}

.pointer-charger {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/charger-pointer.png") no-repeat;
}

.pointer-charger-busy {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/charger-pointer-busy.png") no-repeat;
}

.pointer-toilet {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-toilet.png") no-repeat;
}

.pointer-parchel {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-parchel.png") no-repeat;
}

.pointer-wind {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-wind.png") no-repeat;
}

.pointer-bicycle-station {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer_bicycle.png") no-repeat;
}

.pointer-blue {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 42px;
  color: #fff;
  font-size: 12px;
  background: url("../img/icons/pointer-blue.png") no-repeat;
}

.pointer-house {
  cursor: pointer;
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 53px;
  background: url("../img/icons/pointer-house.png") no-repeat;

  .value {
    position: relative;
    top: -32px;
    left: -7px;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
    padding: 3px 5px;
    color: #fff;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.37);

    &.value-heat {
      background-color: $heat;
    }

    &.value-cold-water {
      background-color: $cold-water;
    }

    &.value-hot-water {
      background-color: $hot-water;
    }

    &.value-gas {
      background-color: $gas;
    }

    &.value-electricity {
      background-color: $electricity;
    }

    &.value-solar-panel {
      background-color: $solar-panel;
    }
  }
}

// Jumbotrons

.floating-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 1rem;
  min-width: 100%;

  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }

  .search-wrapper {
    padding: 0;
    margin: 0;
    border-radius: $form-border-radius;
    width: 360px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right: 0 !important;
    }

    .input-group {

      .form-control {
        border: none;
        font-size: 1rem;
        border-radius: $form-border-radius;

        &:focus {
          box-shadow: none;
        }
      }

      .clear-search {
        display: inline-flex;
        padding: 0 0.5rem;
        align-items: center;
        color: $light-text;
        cursor: pointer;
        opacity: 0;
        animation-name: fadeOut;
        animation-duration: .5s;

        &:hover {
          color: darken($light-gray, 20%);
        }

        &.active {
          opacity: 1;
          animation-name: fadeIn;
          animation-duration: .5s;
        }
      }

      .input-group-text {
        border: none;
        background: none;
        border-radius: $form-border-radius;
      }
    }
  }
}

.jumbotron {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  background-color: #fff;
}

.floating-jumbotron {
  .btn-lg {
    padding: 0.8rem 2.4rem;
  }

  .modal-footer {
    box-shadow: none;
  }

  &.layer-filter {
    display: flex;
    padding: 0.2rem 0.5rem;
    margin-left: 1rem;
    line-height: 1;
    margin-bottom: 0;
    align-items: center;
    border-radius: $form-border-radius;

    @include media-breakpoint-down(sm) {
      display: inline-flex;
      margin-left: 0;
      margin-top: 1rem;
    }

    label {
      margin: 0 8px;
      text-align: center;
      cursor: pointer;

      &:hover {
        .icon-s {
          background: darken($gray-dark, 10%);
        }

        .icon-traffic-sensor {
          background: url(../img/icons/traffic-sensor-hover.svg) no-repeat center !important;
          background-size: contain !important;
          width: 35px;
          height: 30px;
        }

        .icon-charger-station {
          background: url(../img/icons/charger-station-hover.svg) no-repeat center !important;
          background-size: contain !important;
          width: 27px;
          height: 27px;
        }
      }
    }

    .icon-s {
      background: $gray-dark;
    }

    .active {
      .icon-s {
        background: $primary-color;
      }

      .icon-traffic-sensor {
        background: url(../img/icons/traffic-sensor-active.svg) no-repeat center !important;
        background-size: contain !important;
        width: 35px;
        height: 30px;
      }

      .icon-charger-station {
        background: url(../img/icons/charger-station-active.svg) no-repeat center !important;
        background-size: contain !important;
        width: 27px;
        height: 27px;
      }
    }
  }

  &.content-wrapper {
    position: absolute;
    display: none;
    padding: 0;
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 0.5s;
    z-index: 2;

    &.active {
      top: 45px;
      display: block;
      width: 100%;
      max-width: 360px;
      max-height: calc(100vh - 45px);
      opacity: 1;
      animation-name: fadeIn;
      animation-duration: 0.5s;
      overflow-y: auto;
    }
  }

  &.playback-wrapper {
    display: inline-flex;
    padding: 0.45rem 0.5rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    color: inherit;


    .play-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;

      i {
        font-size: 2rem;
        cursor: pointer;
        color: $primary-color;
      }

      &.pause {
        .fa-play:before {
          content: "\f04c";
        }
      }

    }

    .playback {
      width: 500px;
      padding: 0 1rem;

      .range {
        margin-top: -5px;

        .range-labels {
          display: flex;
          margin-bottom: 0;
          margin-top: -5px;
          padding: 0;
          text-align: center;
          font-size: 0.8rem;

          li {
            position: relative;
            width: 5px;
            flex: 1 1 auto !important;
            list-style-type: none;

            &:after {
              position: absolute;
              top: -17px;
              display: inline-block;
              content: "";
              width: 1px;
              height: 4px;
              left: 50%;
              border-left: 1px solid #c2c0c2;
            }

            &.time {
              text-indent: -10px;

              &:after {
                top: -17px;
                height: 12px;
                left: 50%;
                border-left: 2px solid #c2c0c2;
              }
            }
          }
        }
      }

      .range-bar {
        .range-field input[type=range] {
          border: none;
          z-index: 1;
        }

        .range-cloud {
          display: none !important;
        }
      }
    }
  }

  &.floating-jumbotron-transparent {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

// List grpup

.list-group {
  position: relative;
  z-index: 2;

  .list-group-item {
    font-size: 0.85rem;
    font-weight: normal;
    cursor: pointer;

    &:hover {
      background: #fbfbfb;
    }

    &.title {
      font-family: "Pebble", Arial, Helvetica, sans-serif;
      font-size: 1rem;
      color: $primary-color;
      text-transform: uppercase;
      padding: 0.4rem 1.25rem;
      background: $light-gray;
    }
  }
}

// Button group

.btn-separate {
  flex-wrap: wrap;
  justify-content: center;
  .btn {
    flex: inherit;
    margin-bottom: 0.5rem;
  }
}

// Graphs

.bar-graph {
  display: flex;
  height: 25px;
  align-items: flex-end;
  justify-content: space-around;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;

  .bar {
    position: relative;
    width: 5px;
    text-align: center;
    background: $primary-color;
    transition: all 0.15s ease-in-out;

    &:hover {
      cursor: pointer;
      background: darken($primary-color, 5%);
    }
  }
}


// Mobility

.timeline-main {
  padding: 0 0.5rem;

  .stepper-vertical {
    margin: 0 !important;
    padding: 0 !important;

    li {
      flex-direction: row;
      padding: 7px 0;
      margin-bottom: 0 !important;
      font-size: 0.85rem;
      font-weight: normal;

      a {
        left: 0 !important;
        padding: 5px !important;

        .circle {
          left: 0 !important;
          margin-left: -4px !important;
          position: relative !important;
          top: 0 !important;
          order: inherit !important;
          width: 13px !important;
          height: 13px !important;
          line-height: 15px !important;
          z-index: 1 !important;
        }
      }

      .label-text {
        display: flex;
        flex-grow: 1;
        padding: 1px;
        cursor: pointer;

        &:hover {
          color: darken($primary-color, 5%);
        }
      }

      .approaching-time {
        position: relative;
        font-weight: normal;
      }

      &.active {
        .label-text {
          color: $primary-color;
          font-weight: bold;
        }
      }

      &.passed {
        .label-text, .approaching-time {
          color: #bababa;
        }
      }
    }
  }

  .stepper-vertical li:not(:last-child):after {
    width: 2px;
    background-color: #9e9e9e;
    top: 20px !important;
    left: 6px !important;
    margin-left: 0 !important;
    height: 100%;
  }
}

.busstop-timetable {
  margin-bottom: 0;

  tbody {
    border-top: 0;
  }

  td {
    vertical-align: middle;
    padding: 0.6rem 0.5rem 0.5rem 0.5rem !important;
    border: none;
    font-size: .85rem;
    cursor: pointer;

    &.no-wrap {
      white-space: nowrap;
    }
  }

  .bus-number {
    display: flex;
    font-weight: bold;
    color: #ff0000;
  }

  .bus-icon {
    position: relative;
    top: 3px;
    float: left;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../img/icons/Bus-icon.svg") no-repeat;
  }

  .bus-line {
    color: #545454;
  }

  .approaching-time {
    position: relative;
    font-weight: bold;

    .approaching-icon {
      position: absolute;
      top: -11px;
      right: -8px;
      width: 15px;
      height: 15px;
      background: url("../img/icons/approaching-icon.svg") no-repeat;
    }
  }
}

// Buildings

.building-info {
  .bulding-chart {
    width: 350px;
    height: 350px;

    .building {
      position: absolute;
      left: 50%;
      margin-left: -40px;
      top: 50%;
      margin-top: -40px;
      width: 80px;
      height: 80px;
      background: url("../img/icons/building.svg") center no-repeat;
      background-size: contain;
    }

    .building-item {
      position: absolute;
      display: flex;

      &.building-item-cold-water {
        .line {
          stroke: $cold-water;
        }
      }

      &.building-item-hot-water {
        flex-direction: row-reverse;

        .line {
          stroke: $hot-water;
        }
      }

      &.building-item-electricity {
        flex-direction: column-reverse;

        .path {
          transform: rotate(76deg);
          position: relative;
          left: -17px;
          top: -12px;
        }

        .line {
          stroke: $electricity;
        }
      }

      &.building-item-gas {
        flex-direction: column-reverse;

        .path {
          transform: rotate(-58deg);
          position: relative;
          left: 36px;
          top: -6px;
        }

        .line {
          stroke: $gas;
        }
      }

      &.building-item-heat {
        flex-direction: column;

        .path {
          transform: rotate(-45deg);
          position: relative;
          left: -38px;
        }

        .line {
          stroke: $heat;
        }
      }

      &.building-item-solar {
        flex-direction: column;

        .line {
          stroke: $solar-panel;
        }
      }
    }

    .building-icon {
      width: 50px;
      height: 50px;
      background-size: contain !important;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      .value {
        position: relative;
        top: -25px;
        font-weight: 500;
        font-size: 13px;
        white-space: nowrap;

        &.bottom {
          top: auto;
          bottom: -50px;
        }
      }

      &.icon-cold-water {
        background: url("../img/icons/building-cold-water.svg") center no-repeat;

        &:hover + path {
          cursor: pointer;

          .line {
            animation: dash-opposite 100s infinite linear;
          }
        }
      }

      &.icon-hot-water {
        background: url("../img/icons/building-hot-water.svg") center no-repeat;
      }

      &.icon-electricity {
        background: url("../img/icons/building-electricity.svg") center no-repeat;
      }

      &.icon-heat {
        background: url("../img/icons/building-heat.svg") center no-repeat;
      }

      &.icon-solar {
        background: url("../img/icons/building-solar-panel.svg") center no-repeat;
      }

      &.icon-gas {
        background: url("../img/icons/building-gas.svg") center no-repeat;
      }

      &:hover + .path {
        cursor: pointer;

        .dash-move {
          animation: dash-move 100s infinite linear;
        }

        .dash-move-opposite {
          animation: dash-move-opposite 100s infinite linear;
        }
      }
    }
  }

  .path.animate {
    cursor: pointer;

    .dash-move {
      animation: dash-move 100s infinite linear;
    }

    .dash-move-opposite {
      animation: dash-move-opposite 100s infinite linear;
    }
  }

  .path {
    padding: 3px;

    .line {
      stroke-width: 3px;
      stroke-dasharray: 7;

      @keyframes dash-move {
        to {
          stroke-dashoffset: 1000;
        }
      }

      @keyframes dash-move-opposite {
        to {
          stroke-dashoffset: -1000;
        }
      }
    }
  }
}


// Cards

.card-wrapper {
  height: auto;
  width: 280px;
  margin: 0 1rem 1rem 1rem;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    transition: transform .15s ease-in-out ,box-shadow .15s ease-in-out;
  }

  &.card-wrapper-small-light {
    width: 170px;
    .card {
      .view {
        display: flex;
        width: 170px;
        align-items: center;
        justify-content: center;
        height: 140px;
        background: url("../img/card-bg-light.svg") no-repeat;

        .icon {
          position: relative;
          top: -10px;
          width: 85px;
          height: 68px;
        }
      }

      h2 {
        max-width: 135px;
      }
    }
  }

  .card {
    min-width: inherit;
    .view {
      display: flex;
      width: 280px;
      align-items: center;
      justify-content: center;
      height: 214px;
      background: url("../img/card-bg.png") no-repeat;

      .icon {
        position: relative;
        top: -10px;
        width: 165px;
        height: 125px;

        &.icon-mobility {
          top: -6px;
          width: 195px;
        }

        &.icon-other {
          width: 160px;
          height: 135px;
          top: -16px;
        }
      }
    }

    .card-ribbon {
      position: absolute;
      background: $primary-color;
      top: 24px;
      right: -45px;
      width: 160px;
      text-align: center;
      color: #fff;
      padding: 5px 10px;
      transform: rotateZ(45deg);
    }

    .card-body {
      display: flex;
      flex-direction: column;
      position: relative;
      cursor: pointer;

      h2 {
        margin-bottom: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 245px;
      }

      &.resize {
        resize: both;
        overflow: auto;
      }

      .delegation-type {
        position: absolute;
        right: 10px;
        top: 10px;

        &.delegation-legal {
          width: 17px;
          height: 28px;
          background: url("../img/icons/icon-legal.svg") no-repeat;
          background-size: contain;
        }

        &.delegation {
          width: 25px;
          height: 28px;
          background: url("../img/icons/icon-delegation.svg") no-repeat;
          background-size: contain;
        }
      }
    }

    .card-title {
      text-transform: uppercase;
      font-size: 1.25rem;
    }
  }

  &.card-simple {
    .card {
      overflow: hidden;
      position: relative;
      width: 100%;
      line-height: 1;

      h4 {
        width: 80%;
        margin-bottom: 5px;
      }

      .rounded-bottom {
        display: flex;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 40px;
        justify-content: center;
        align-items: center;
        transform: translateY(40px);
        transition: all .5s ease-in-out;
        background: $gray-500;
        cursor: pointer;
      }
    }

    &:hover {
      .rounded-bottom {
        transform: translateY(0);
        transition: all .5s ease-in-out;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.card-wrapper-small-light {
    width: 170px;
    .card {
      .view {
        display: flex;
        width: 170px;
        align-items: center;
        justify-content: center;
        height: 140px;
        background: url("../img/card-bg-light.svg") no-repeat;

        .icon {
          position: relative;
          top: -10px;
          width: 85px;
          height: 68px;
        }
      }

      h2 {
        max-width: 135px;
      }
    }
  }
}

//Dashboard widgets

.dashboard-item {
  height: 100%;
  position: relative;
  border: 1px solid $border-color;
  background: #fff;
  display: flex;
  flex-flow: column;

  .widget-body {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
  }

  .widget-titlebar {
    display: flex;
    flex: 0 1 auto;
    border-bottom: 1px solid $border-color;
    padding-right: 45px;
    padding-left: 1rem;
    align-items: center;

    .widget-menu {
      position: absolute;
      right: 0;
      top: 0;
    }

    h2 {
      padding-top: 10px;
      height: 45px;
      margin-bottom: 0;
      align-items: center;
    }
    &:hover {
      .drag-handler {
        opacity: 1;
        transition: all .2s ease-in-out;
      }
    }
  }

  &.dashboard-item-other {
    .widget-titlebar {
      display: block;
      padding-top: 1rem;
      border-bottom: 0;
    }

    .title {
      display: block;
      text-transform: uppercase;
      font-size: 1.625rem;
      margin-top: 1rem;
      color: $light-text;
    }
    .content {
      border-top: 1px solid $border-color;

      h5 {
        font-family: inherit;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.1rem;
      }

      ul li {
        padding: 1rem 0;
        border-top: 1px solid $border-color;
        &:first-of-type {
          border-top: none;
        }
      }

      .icon-bullet {
        margin: 0 1rem;
      }
    }

    .status-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.status-done {
        color: $green-light;
        .icon-checked {
          background: $green-light;
        }
        .btn-link {
          color: $green-light;
        }
      }
    }
  }

  .drag-handler {
    position: absolute;
    top: 0;
    left: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    opacity: 0;
    mask: url(../img/icons/draggable-icon.svg) no-repeat center;
    background: $light-text;
    cursor: pointer;

    @include hover() {
      background: $body-color;
    }
  }

  .close {
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
    span {
      display: block;
      text-transform: uppercase;
      font-size: $small-font-size;
      font-weight: 400;
    }
  }

}



// Gridster

gridster {
  padding: 0 !important;
  background: $bg-gray !important;
}

// Selectable list

.selectable-list {
  .exchange-icon {
    font-size: 2rem;
    color: $gray-400;
  }
  .filter-bar, .footer-bar {
    background: $gray-800;
    padding: 0.7rem;
  }

  .filter-bar {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0.4rem;

    &.filter-bar-min {
      min-height: 45px;
      h3 {
         margin: 2px 0 0 0;
       }
    }

    h3 {
      margin: 0;
      display: flex;
      flex-grow: 2;
    }
  }

  .footer-bar {
    align-items: center;
  }

  .list-item {
    position: relative;
    margin-bottom: 0.1rem;
    padding: 0.5rem 0.7rem;
    background: #fff;
    cursor: pointer;

    .light i {
      font-size: 1rem;
    }

    &:hover, &.selected, &.focused {
      background: $bg-active;
    }

    .remove-button {
      position: absolute;
      right: 15px;
      top: 8px;
      opacity: 0;
    }

    &:hover {
      .remove-button {
        opacity: 1;
        transition: $transition-base;
      }
    }

    &.draggable {
      padding-left: 30px;

      &:hover {
        // background: #fff;
        cursor: inherit;
      }

      .drag-handler {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 5px;
        cursor: all-scroll;
        width: 20px;
        height: 20px;
        mask: url(../img/icons/draggable-icon.svg) no-repeat center;
        background: $light-text;

        @include hover() {
          background: $body-color;
        }
      }
    }
  }
}

// Page title bar

.page-title-bar {
  background: #fff;
  border-bottom: 1px solid $border-color;
}

// Datapoints

.value {
  margin-left: 1rem;
  font-size: $badge-font-size;
  color: $light-text;
  font-family: $font-family-base;
  text-transform: inherit;
}

.accordion {
  padding: 2rem 0;
  border-bottom: 1px solid $border-color;

  &:last-of-type {
    border-top: none;
  }

  .accordion-header {
    padding-bottom: $modal-header-padding-y;
  }
}

// List

.list {
  .list-item {
    margin-bottom: 0.3rem;
    padding: $modal-header-padding-y $modal-header-padding-x;
    background: #fff;
  }
}

// Questionnaires

.separator {
  display: block;
  margin: 3rem 0;
  border-bottom: 5px solid #e8e8e8;
}

.variable {
  margin-top: 2rem;
  border-left: 5px solid $primary-color;
  background: #eee;

  &.view {
    border-left: none;
  }

  .header {
    display: flex;
    min-height: 42px;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.2rem 0;
    background: #E8E8E8;
    border-bottom: 2px solid #fff;

    .marker {
      font-size: 1.8rem;
    }
  }

  .content {
    padding: 1rem 1.5rem;
  }
}

.equal {
  font-size: 2rem;
  color: $gray-400;
}

app-questionnaire-fields {
  display: flex;
  min-height: calc(100vh - 222px - 4.5rem);
  margin: -1.5rem !important;
}

.questions-list {
  flex: 0 0 400px;
  box-shadow: -3px 0px 5px -2px rgba(0, 0, 0, 0.25);
}

.box {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: #fff;
  border: 1px solid $border-color;

  .question {
    margin-bottom: 0.5rem;
    color: $body-color;
    font-size: $font-size-base;
    font-weight: 600;
    text-transform: inherit;
  }
}

.highlight {
  padding: 1rem 1.5rem;
  margin-top: 0.5rem;
  background: $bg-gray;
}

.unit-example {
  padding: 0.5rem 1rem;
  background: $gray-400;
}

// Collapsable box

.box-collapsable {

  .box-header {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    @include hover-focus() {
      color: $navbar-light-hover-color;
    }
    &:before {
      position: absolute;
      left: 0;
      top: -3px;
      font-family: "Font Awesome 6 Pro";
      content: "\f107";
      font-size: 1.4rem;
      font-weight: 400;
    }

    .question {
      margin-bottom: 0;
    }
  }

  .box-body {
    padding-left: 30px;
    margin-top: 1rem;
  }
}

// Bar chart

.custom-bar-chart {
  position: relative;
  display: flex;
  height: 35px;
  width: 100%;
  font-size: $small-font-size;
  font-weight: 400;
  font-style: italic;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  .bar {
    position: absolute;
    display: flex;
    height: 33px;
    background: lighten($primary-color, 50%);
    border-radius: $border-radius;
  }
}

.tos {
  ol {
    counter-reset: item;
    list-style-position: outside;
    padding: 5px 0;
  }
  li {
    display: block;
    padding: 0;
  }
  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }
}

.sourceAssetTree-wrapper {
  font-size: 11px;
  font-family: Arial;
  margin-left: -1rem;

  ul.sourceAssetTree-item {
    list-style: none;
    padding-left: 0;
    position: relative;

    &:not(:first-child) {
      &:after {
        content: "\f343";
        font-family: "Font Awesome 6 Pro";
        left: -20px;
        top: 0px;
        position: absolute;
        font-size: 12px;
        color: $primary-color;
      }
    }
  }
  @for $i from 1 through 10 {
    ul:nth-child(3n + #{$i}) {
      margin-left: $i * 1.5rem;
    }
  }
}

.fw-bold {
  font-weight: 700;
}
.custom-list {
  .list-item:not(:last-child) {
    border-bottom: 1px solid $table-border-color;
  }
}

.share-list {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .share-list-item {
    float: left;
    padding: 0;
    width: 29px;
    height: 29px;

    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    color: #fff;
    background-color: $primary-color;

    &:not(:first-child) {
      margin-left: -7px;
    }

    span {
      text-align: center;
      vertical-align: middle;
    }
  }

  &:hover .share-list-item {
    background: darken($primary-color, 5%);
  }
}


#landing {
  &__wrapper {
    background-color: $primary-color;
    width: 100%;
    min-height: 100%;
    background-image: url('../img/telia-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    font-family: "Telia Sans", Arial, Helvetica, sans-serif;
    padding-top: 200px;

    @include media-breakpoint-down(sm) {
      padding-top: 100px;
    }

    h4 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      font-family: "Telia Sans", Arial, Helvetica, sans-serif;
      text-transform: none;
    }

    .logo-wrapper {
      border-right: 1px solid $white;
      padding-right: 1.875rem;
      border-right: 1px solid rgba(255, 255, 255, 0.38);

      .dal-logo {
        width: 240px;
        height: auto;
      }

      @include media-breakpoint-down(sm) {
        border-right: 0;
      }
    }

    .introduction {
      text-transform: uppercase;
      line-height: 22px;
      font-size: 1rem;
      text-align: center;
      margin-top: 1rem;
    }

    .btn-login {
      background-color: $secondary-purple-color;
      color: $white;
      padding: 0.875rem 1.5rem;
      position: relative;
      padding-right: 46px;
      font-weight: 700;
      text-transform: none;
      font-size: 16px;

      &:after {
        background-image: url('../img/icons/icon__arrow-right.svg');
        width: 9px;
        height: 15px;
        content:"";
        position: absolute;
        right: 22px;
        top: 17px;
      }

      &:hover,
      &:active {
        background-color: $primary-color;
        color: $white;
      }
    }

    .btn-demo-login {
      background-color: $gray-500;
      border-color: $gray-500;
      color: $primary-color;
      padding: 0.875rem 1.5rem;
      font-weight: 700;
      text-transform: none;
      font-size: 16px;
    }
  }
}

#setup-wizard {

  .btn-action {
    background-color: $darker-purple;
    color: $white;
    position: relative;
    padding: 0.875rem 46px 0.875rem 1.5rem;
    font-weight: 700;
    text-transform: none;
    font-size: 16px;
  }

  .page-title-bar {
    padding: 1.5rem 0;
  }

  .page-title-bar h2 {
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    font-style: normal;
  }


  .nav-item .nav-link {
    padding: 0.5rem 0;
  }

  .nav-item {
    font-size: 22px;
    padding: 0 70px 0 0;
  }

  .nav-item:not(:first-child) {
    margin-left: 60px;
  }

  .nav-item:before {
    font-size: 22px;
    right: 0;
    top: 10px;
  }

  .btn-action:after {
    background-image: url(../img/icons/icon__arrow-right.svg);
    width: 9px;
    height: 15px;
    content: "";
    position: absolute;
    right: 22px;
    top: 17px;
  }

}
