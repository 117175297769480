// Daterangepicker

.rounded {
  .mat-form-field {
    .mat-form-field-flex {
      border-radius: $border-radius-base;
    }

    .mat-form-field-wrapper {
      border-radius: $border-radius-base;
    }
  }
}

.mat-form-field {

  .mat-form-field-wrapper {
    padding-bottom: 0;
    background: #fff;
    border-radius: $border-radius;
  }

  .mat-form-field-underline {
    background: none;
  }

  .mat-form-field-flex {
    height: calc(1.5em + #{$input-btn-padding-x} + 2px);
    padding: $input-btn-padding-y $input-btn-padding-x;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    align-items: center;
  }

  &.mat-field-auto {
    .mat-form-field-infix {
      width: auto !important;
    }
  }

  .mat-form-field-infix {
    padding: 0;
    width: 100%;
    border: none;
    .mat-form-field-label-wrapper {
      display: none;
    }
    input.mat-input-element {
      margin-top: 0;
      display: flex;
      height: 100%;
    }
  }

  .mat-form-field-suffix {
    .ngx-mat-drp-calendar {
      position: relative;
      top: 2px;
      width: 15px !important;
      height: 16px !important;
      mask: url("../../assets/img/icons/calendar-icon.svg") !important;
      background: $light-text;
      cursor: pointer;

      @include hover() {
        background: $body-color;
      }
    }
  }
}

.ngx-mat-drp-calendar-container {
  display: flex;
  justify-content: space-around;
  min-width: 720px;
}

.ngx-mat-drp-date-input {
  color: $input-color !important;
  font-weight: $font-weight-light !important;
}



.ngx-mat-drp-presets-list li {
  padding: 0.6rem 1.37rem !important;
  font-size: 0.777rem;
  line-height: 1.5;
  border-radius: 1.8rem;
  color: #2F3336 !important;
  background-color: #F7F8FA !important;
  border-color: #F7F8FA;
  font-weight: 400;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: #2F3336 !important;
    background-color: #dfe3eb !important;
    border-color: #d8dde6;
  }
}

.ngx-mat-drp-controls {
  margin-bottom: 0 !important;

  .mat-primary {
    width: 50%;
  }
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  padding: 0.37rem 1.37rem;
  font-size: 0.777rem;
  line-height: 1.5 !important;
  border-radius: 1.8rem;
  color: #fff !important;
  background-color: $primary-color;
  border-color: $primary-color;
  font-weight: 400;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #8008be;
    border-color: #7808b2;
    cursor: pointer;
  }
  &:focus {
    color: #fff;
    background-color: #8008be;
    border-color: #7808b2;
    box-shadow: 0 0 0 1px rgba(168, 47, 231, 0.5);
    outline: 0;
  }
}

.mat-button.mat-button-base {
  padding: 0.37rem 1.37rem;
  font-size: 0.777rem;
  line-height: 1.5 !important;
  border-radius: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
}

.mat-calendar-body-selected {
  background-color: $primary-color !important;
}

.mat-calendar-body-cell-range-in, .mat-calendar-body-cell-range-is, .mat-calendar-body-cell-range-from, .mat-calendar-body-cell-range-to {
  .mat-calendar-body-cell-content {
    color: white;
    background-color: $primary-color !important;
  }
}

.mat-calendar-body-cell-range-in {
  .mat-calendar-body-cell-content {
    width: 100%;
    left: 0;
    right: 0;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 0;
  }
}

.mat-calendar-body-cell-range-from {
  .mat-calendar-body-cell-content {
    width: 95%;
    right: 0;
    padding-right: 5%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.mat-calendar-body-cell-range-to {
  .mat-calendar-body-cell-content {
    width: 95%;
    left: 0;
    padding-left: 5%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.mat-calendar-body-cell-range-is {
  .mat-calendar-body-cell-content {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

:not(.mat-calendar-body-cell-range-in, .mat-calendar-body-cell-range-from, .mat-calendar-body-cell-range-to) {
  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: #e8e8e8 !important;
    background: #e8e8e8 !important;
  }
}

.mat-calendar-body-cell {
  padding: 16px !important;
  width: auto !important;
}

.ngx-mat-drp-date-label {
  background: none !important;
}

.mat-calendar-header {
  padding-top: 0 !important;
}
