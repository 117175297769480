.pagination {
  margin-bottom: 0;
  .page-link {
    border-radius: $border-radius-base;
  }
  .page-item {
    &.disabled .page-link {
      background-color: inherit;
    }
  }
  .page-item.active {
    .page-link {
      background: none;
    }
  }
  .pagination-next,
  .pagination-prev  {
    .page-link {
      background: none;
    }
  }
}
