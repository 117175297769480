// Colors
$primary-color: #5E0092;
$secondary-purple-color: #990AE3;
$menu-color: #690098;
$dark-purple: #4C0467;
$darker-purple: #29003E;
$secondary-color: #3bcc66;
$green-base: #3bcc66;
$green-light: #00ED32;
$blue-bright: #3999ff;
$blue-base: #3999ff;
$gray-dark: #717171;
$light-gray: #fbfbfb;
$light: #F7F8FA;

$heat: #ffcd64;
$gas: #e25822;
$electricity: #00cdff;
$solar-panel: #0076ff;
$cold-water: #003bff;
$hot-water: #ff3264;

//
$white:  #fff;
$gray-100: #000000;
$gray-200: #303438;
$gray-300: #ADB5BD;
$gray-400: #D5D5D5;
$gray-500: #F1F3F5;

$gray-600: #EEF0F3;
$gray-700: #818181;
$gray-800: #E8E8E8;
$gray-900: #2F3336;
$black:  #000;
//

$body-bg: #fff;
$body-color: #303438;
$link-color: $primary-color;
$text-color: #50565D;
$bg-gray: #F2F2F2;
$bg-active: lighten($primary-color, 50%) !default;
$light-text: #959595;
$border-color: #D5D5D5;
$gray-700: #818181;
$btn-color: #878787;
$pagination-hover-bg: #FAF6FF;
$bg-highlight: #FAF6FF;
$table-head-color: #e8e8ed;


$theme-colors: (
  primary: $primary-color,
  secondary: #878787,
  success: #3bcc66,
  info: #3999ff,
  warning: #ffcd64,
  danger: #ff3264,
  lighter: #818181,
  light: #F7F8FA,
  dark: #303438,
  black: #000,
  white: rgba(#fff, .45)
);

$border-radius:                .25rem;
$border-radius-base:           1.8rem;
$sidebar-width:                0;
$component-active-bg:          $primary-color;

// Typo
$font-family-sans-serif:      'Open Sans', Roboto, Helvetica, Arial, sans-serif;
$font-family-telia-sans:      'Telia Sans', Roboto, Helvetica, Arial, sans-serif;
$font-family-base:             $font-family-telia-sans;
$headings-font-family:         'Pebble', Helvetica, Arial, sans-serif;
$headings-margin-bottom:      1.2rem;

$root-font-size-base:         14px;
$root-font-size-min:          12px;
$root-font-size-max:          16px;

$font-size-base:              1rem;
$font-size-sm:                .777rem;
$font-size-lg:                $font-size-base * 1.047;
$small-font-size:             0.857rem;
$btn-font-size:              1rem;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bolder:          600;

$text-muted:                  $gray-700 !default;

//Navigation

$navbar-nav-link-padding-x:  1rem !default;

// Jumbotrons
$box-shadow:                  0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
$form-border-radius:          1.8rem;


// Buttons and forms
$btn-padding-y:               .37rem;
$btn-padding-x:               1.37rem;

$btn-padding-y-sm:            .37rem;
$btn-padding-x-sm:            1.37rem;

$btn-border-width:            1px;

$btn-font-weight:             $font-weight-normal;
$btn-box-shadow:              none;
$btn-disabled-opacity:        .65;
$btn-active-box-shadow:       none;

$btn-link-disabled-color:     $gray-600;

$btn-block-spacing-y:         .5rem;

$btn-border-radius:           $border-radius-base;
$btn-border-radius-lg:        $border-radius-base;
$btn-border-radius-sm:        $border-radius-base;

$input-btn-focus-width:       1px;
$input-btn-focus-color:       transparent;
$btn-box-shadow:              none;
$btn-focus-width:             $input-btn-focus-width;

$border-width:                1px !default;
$input-border-radius:         $border-radius !default;
$input-border-radius-lg:      $border-radius-base !default;
$input-font-weight:           300;
$input-line-height:           1.5 !default;
$input-height-border:         $border-width * 2 !default;
$input-placeholder-color:     $light-text !default;
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-focus-border-color:     lighten($component-active-bg, 25%) !default;
$input-disabled-bg:            $gray-600 !default;
$custom-control-indicator-disabled-bg: $gray-600 !default;
$custom-control-indicator-active-disabled-bg: lighten($primary-color, 45%) !default;
$custom-control-label-disabled-color: $body-color!default;

//Modals
$modal-inner-padding:          2rem !default;
$modal-header-padding-y:       1rem !default;
$modal-header-padding-x:       2rem !default;
$close-color:                  $light-text;
$modal-backdrop-opacity:       0.3;
$modal-fade-transform-xxl:     translate(100%,0);

// Dropdowns
$dropdown-divider-bg:          $border-color !default;
$dropdown-box-shadow:          $box-shadow !default;
$dropdown-link-color:          $body-color !default;
$dropdown-link-hover-color:    darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:       $bg-gray !default;
$dropdown-item-padding-y:      0.8rem;
$dropdown-link-active-color:   $body-color !default;
$dropdown-link-active-bg:      darken($bg-gray, 5%) !default;

// Datatabel
$datatable-header-cell-color:  $gray-700;
$datatable-footer-cell-color:  $gray-700;
$datatable-header-cell-background: $table-head-color;
$datatable-group-header-background: $table-head-color;
$datatable-row-detail-background: $table-head-color;
$datatable-body-cell-color:    $body-color;

//Pagination
$pagination-color:                  $text-color !default;
$pagination-bg:                     none !default;
$pagination-border-width:           none !default;
$pagination-border-color:           none !default;

$pagination-hover-bg:               $pagination-hover-bg !default;
$pagination-hover-border-color:     $pagination-hover-bg !default;

$pagination-active-color:           $link-color !default;
$pagination-active-bg:              none !default;
$pagination-active-border-color:    none !default;

$pagination-disabled-color:         $gray-700 !default;
$pagination-disabled-bg:            none !default;
$pagination-disabled-border-color:  none !default;

//Custom control

$custom-control-indicator-size:         1.2rem !default;
$custom-control-indicator-border-color: $gray-700 !default;
