.modal-content {
  background: $bg-gray;
  border: none;
}

.modal-header {
  position: relative;
  border: none;
  padding-right: 2rem;
  background: white;

  &.header-border {
    border-bottom: 1px solid $border-color;
  }

  .modal-title {
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
  }

  .close {
    display: flex;
    position: absolute;
    right: 0;
    width: 3rem;
    height: 3.4rem;
    top: 0;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
    }
  }
}

.modal-body {
  background: $bg-gray;
}

.modal-footer {
  padding: $modal-header-padding-y $modal-header-padding-x;
  background: $bg-gray;
  border: none;
  // box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-top: 1px solid $border-color;
  position: sticky;
  bottom: 0;
}


// XXL modal

.modal.fade .modal-xxl,
.modal.fade .modal-xl,
.modal.fade .modal-xl-half {
  transform: $modal-fade-transform-xxl;
}

.modal.show .modal-xxl,
.modal.show .modal-xl,
.modal.show .modal-xl-half {
  transform: none;
}

.modal-xxl {
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
  max-width: inherit;
  margin: 0;
  min-height: 100vh;

  .modal-content {
    border-radius:0;
    min-height: 100vh;
    border: none;
  }

  h3 {
    color: $gray-700;
  }
}

.modal-xl {
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
  max-width: inherit;
  margin: 0;
  min-height: 100vh;

  .modal-content {
    border-radius:0;
    min-height: 100vh;
    border: none;
  }

  h3 {
    color: $gray-700;
  }
}

.modal-xl-half {
  left: auto;
  right: 0;
  margin-left: auto !important;
  width: calc((100% - #{$sidebar-width})/2);
  max-width: inherit;
  margin: 0;
  min-height: 100vh;

  .modal-content {
    border-radius:0;
    min-height: 100vh;
    border: none;
  }

  h3 {
    color: $gray-700;
  }

  .action-buttons {
    white-space: nowrap;
  }
}


