// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css"; // makes datepicker look nice

@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';

@import 'material-icons/iconfont/material-icons.scss';

@import "variables";

@import "bootstrap";
@import "ng-select";
@import "ngx-toast";
@import "../vendor/fontawesome-pro/scss/fontawesome";
@import "../vendor/fontawesome-pro/scss/solid";
@import "../vendor/fontawesome-pro/scss/light";
@import "../vendor/fontawesome-pro/scss/regular";
@import "../vendor/fontawesome-pro/scss/brands";



// => Partials
// ------------------------------------------------------------------------------

@import "main";
@import "header";
@import "nav";
@import "icons";
@import "type";
@import "forms";
@import "tables";
@import "modals";
@import "buttons";
@import "daterangepicker";
@import "pagination";
@import "drag-drop";
@import "alerts";
@import "slider";



//Material

.mat-tree {
  .mat-tree-node {
    min-height: 30px;
    .mat-tree-node-toggle {
      width: 100%;
      text-align: left;
      background-color: $bg-gray;
      border-radius: $border-radius;
      margin: 0.3rem 0;
    }
  }
  .mat-icon-button {
    margin: 0.5rem !important;
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
}


.layout-content {
  min-height: calc(100vh - 170px);
  background-color: #f4f4f9;
}

@media (min-width: 1000px) {
  .header-container,
  .content-container {
    max-width: 1244px;
    width: 100%;
    margin: 0 auto;
  }
}
